<template>
  <div class="sharePromote">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title"><svg-icon class="sharePromotion" iconClass="sharePromotion"></svg-icon></div>
      <div class="rightBtn"></div>
    </div>
    <div class="shareMain">
      <div class="qrCodeBox">
        <div class="dottedLine">
          <div class="dotted" v-for="index in 60" :key="index"></div>
        </div>
        <div class="topBox">
          <div class="avatarImg"></div>
          <div class="qrText">
            <div>软妹社</div>
            <div>一款边吃瓜边赚钱的APP</div>
          </div>
        </div>
        <div class="dottedLine1">
          <div class="dotted" v-for="index in 60" :key="index"></div>
        </div>
        <div class="qrcode">
          <div ref="qrCodeUrl"></div>
        </div>
        <div class="promotionCode">
          推广码:
          <span>{{ userInfo.promotionCode }}</span>
        </div>
        <div class="giveAway">分享好友立赠送软妹社专属会员</div>
        <div class="officialWebsite">官网地址: {{ userInfo.promoteURL }}</div>
        <div class="shareBtnBox">
          <div class="shareBtn" @click="showTip">截图保存</div>
          <div class="shareBtn saveBtn" @click="copyUrl">复制链接</div>
        </div>
      </div>
      <div class="steps"></div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { Toast } from 'vant';
export default {
  name: 'SharePromote',
  data() {
    return {
      userInfo: '',
    };
  },
  created() {
    this.$store.dispatch('user/getUserInfo');
    this.userInfo = this.$store.getters.userInfo;
    this.generateCode();
  },
  methods: {
    // 生成二维码
    generateCode() {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.userInfo.promoteURL,
          width: 142,
          height: 142,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    copyUrl() {
      this.$copyText(this.userInfo.promoteURL).then(
        (e) => {
          Toast('复制成功');
        },
        (e) => {
          Toast('复制失败');
        },
      );
    },
    showTip() {
      Toast('请手动截图分享');
    },
  },
};
</script>

<style lang="scss" scoped>
.sharePromote {
  height: 100%;
  background: url('./../../../assets/png/actCredentialsBg.png') no-repeat;
  background-size: 100% 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      text-align: center;
      .sharePromotion {
        height: 20px;
        width: 90px;
      }
    }

    .rightBtn {
      width: 80px;
    }
  }

  .shareMain {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 10px;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    .qrCodeBox {
      width: 301px;
      height: 481px;
      margin: 25px auto 0;
      background: url('../../../assets/png/actBg.png') no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      position: relative;
      .dottedLine {
        display: flex;
        margin: 22px 10px 0px;
      }
      .dotted {
        height: 1px;
        width: 3px;
        margin: 0 1px;
        background-color: rgb(255, 194, 103);
      }
      .dottedLine1 {
        display: flex;
        margin: 10px 10px;
      }
      .topBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6px 0;

        .avatarImg {
          width: 62px;
          height: 62px;
          background-image: url('../../../assets/png/appLogoCircle.png');
          background-size: 100% 100%;
          border-radius: 50%;
        }
        .qrText {
          font-size: 16px;
          text-align: center;
        }
      }

      .qrcode {
        width: 142px;
        height: 142px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .promotionCode {
        text-align: center;
        font-size: 22px;
        color: #171717;
        margin-top: 12px;
      }

      .giveAway {
        font-size: 14px;
        text-align: center;
        margin-top: 7px;
        color: rgb(102, 102, 102);
      }

      .officialWebsite {
        margin-top: 1px;
        font-size: 14px;
        text-align: center;
        color: rgb(102, 102, 102);
      }

      .shareBtnBox {
        position: absolute;
        bottom: 18px;
        width: 100%;
        padding: 0 33px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .shareBtn {
          width: 114px;
          height: 38px;
          line-height: 34px;
          border-radius: 4px;
          font-size: 16px;
          text-align: center;
          color: rgb(255, 103, 143);
          border: 2px solid rgb(255, 103, 143);
          box-sizing: border-box;
        }
        .saveBtn {
          color: #fff;
          background-color: rgb(255, 103, 143);
        }
      }
    }

    .steps {
      width: 351px;
      height: 349px;
      margin: 20px auto;
      border-radius: 6px;
      background: url('../../../assets/png/promoSteps.jpg') no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
